// Import and register all your controllers from the importmap under controllers/*
import { application } from "~/controllers/application"

// Eager load all controllers defined in the import map under controllers/**/*_controller
import { registerControllers } from 'stimulus-vite-helpers'
const controllers = import.meta.globEager('./**/*_controller.js')
registerControllers(application, controllers)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)
