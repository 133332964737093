import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "label", "error", "input" ]
  static classes = [ "labelError", "inputError" ]

  initialize() {
  }

  connect() {
    console.log('connected')
  }

  removeError() {
    if (this.hasErrorTarget) {
      this.errorTarget.remove()
    }

    this.inputTarget.classList.remove(...this.inputErrorClasses)
    this.labelTarget.classList.remove(...this.labelErrorClasses)
    // this.removeClasses(this.inputTarget, this.inputErrorClasses)
    // this.removeClasses(this.labelTarget, this.labelErrorClasses)
  }

  removeClasses(element, classes) {
    classes.forEach(cssClass => {
      element.classList.remove(cssClass)
    })
  }
} 

