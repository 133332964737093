import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
  }

  connect() {
  }

  formatToPhone(event) {
    if(this.isModifierKey(event)) {return;}

    const input = event.target.value.replace(/\D/g,'').substring(0,11); // First eleven digits of input only
    const areaCode = input.substring(0,2);
    const middle = input.substring(2,7);
    const last = input.substring(7,11);

    if(input.length > 6){event.target.value = `(${areaCode}) ${middle}-${last}`;}
    else if(input.length > 2){event.target.value = `(${areaCode}) ${middle}`;}
    else if(input.length > 0){event.target.value = `(${areaCode}`;}
  }

  enforceFormat(event) {
    if(!this.isNumericInput(event) && !this.isModifierKey(event)){
      event.preventDefault();
    }
  }

  isNumericInput(event) {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
    )
  }

  isModifierKey(event) {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
        (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        (
            // Allow Ctrl/Command + A,C,V,X,Z
            (event.ctrlKey === true || event.metaKey === true) &&
            (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
        )
  }
} 

